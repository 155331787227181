.mentions {
  border-color: rgb(209, 213, 219) !important;
  color: rgb(17, 24, 39);
  background-color: rgb(255, 255, 255);
  border-radius: 0.375rem;
  width: 100%;
  display: block;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 100%;
  min-height: 25px;
}
.mentions--singleLine .mentions__highlighter {
  height: 100%;
  padding: 5px 10px;
  margin-top: -1px;
  margin-left: -4px;
  word-wrap: break-word;
  white-space: pre-line;
}
.mentions--singleLine .mentions__input {
  padding: 5px 10px;
  border-radius: 0.375rem;
  border-color: rgba(209, 213, 219, 1);
}

.mentions--multiLine .mentions__control {
  display: inline-block;
  width: 100%;
  min-height: 25px;
}
.mentions--multiLine .mentions__highlighter {
  height: 100%;
  padding: 5px 10px;
  margin-top: -1px;
  margin-left: -4px;
  word-wrap: break-word;
  white-space: pre-line;
}
.mentions--multiLine .mentions__input {
  padding: 5px 10px;
  border-radius: 0.375rem;
  border-color: rgba(209, 213, 219, 1);
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: scroll;
  max-height: 160px;
  min-width: 300px;
  padding: 16px 8px !important;
  border-radius: 16px;
  transform: translateY(-200px);
}

.mentions__suggestions__item {
}

.mentions__suggestions__item--focused {
  @apply bg-gray-50;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.mentions__mention strong:nth-child(add) {
  /* margin-left: 15px; */
}

.mentions__suggestions{
  background-color: inherit !important;
}