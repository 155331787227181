.dot-wave {
  --uib-size: 67px;
  --uib-speed: 1s;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  width: var(--uib-size);
  height: calc(var(--uib-size) * 0.50);
  padding-top: calc(var(--uib-size) * 0.34);
}

.dot-wave__dot {
  flex-shrink: 0;
  width: calc(var(--uib-size) * 0.12);
  height: calc(var(--uib-size) * 0.12);
  border-radius: 50%;
  will-change: transform;
}

.dot-wave__dot:nth-child(1) {
  animation: jump var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.45) infinite;
}

.dot-wave__dot:nth-child(2) {
  animation: jump var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.3) infinite;
}

.dot-wave__dot:nth-child(3) {
  animation: jump var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.15) infinite;
}

.dot-wave__dot:nth-child(4) {
  animation: jump var(--uib-speed) ease-in-out infinite;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-200%);
  }
}