// This scss file is commonly used for all the register steps/pages

.email-otp-verification {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        color: $dark-color-one;
        margin-top: 42px;
    }

    .user-email {
        font-size: 14px;
        letter-spacing: 0.015em;
        color: $dark-color-four;
        margin-top: 16px;
    }

    .email-icon {
        margin-top: 14px;
        height: 127px;
        width: 127px;
    }

    .submit-button-wrapper {
        margin-top: 21px;
    }

    .otp-input-style {
        backdrop-filter: blur(12.5px);
        width: 79px !important;
        border: none;
        height: 56px;
        border-radius: 4px;
        background: $dark-color-three;
        font-size: 25px;
        color: $dark-color-two;
        caret-color: $green-primary;

        &:focus {
            outline: none;
        }
    }

    .otp-input-container-style {
        margin-top: 32px;
    }

    .card-footer {
        text-align: right;
        margin-top: 32px;

        .resend-text {
            cursor: pointer;
            font-size: 14px;
            color: $dark-color-op2;
            letter-spacing: 0.02em;
        }
    }
}

.mobile-otp-verification {
    @extend .email-otp-verification;
}

.onboading-mobile-number {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-footer {
        display: flex;
        margin-top: 40px;
        justify-content: space-between;

        .footer-text {
            cursor: pointer;
            font-size: 14px;
            color: $dark-color-op2;
            letter-spacing: 0.02em;
        }
    }

    .title {
        font-weight: 600;
        font-size: 20px;
        color: $dark-color-one;
        margin-top: 42px;
    }

    .subtitle {
        font-size: 14px;
        letter-spacing: 0.015em;
        color: $dark-color-four;
        margin-top: 16px;
        letter-spacing: 0.015em;
        line-height: 150%;
        text-align: center;
    }

    .submit-button-wrapper {
        margin-top: 32px;
        text-align: center;
    }

    .input-label {
        font-size: 18px;
        line-height: 18px;
        color: $dark-color-one;
        font-weight: 600;
        margin-top: 45px;
    }

    .mobile-number-input-wrapper {
        height: 56px;
        background: $dark-color-three;
        backdrop-filter: blur(10px);
        width: 338px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        margin-top: 22px;

        .info-wrapper {
            width: 30%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .flag {
                width: 23px;
                height: 23px;
                margin-bottom: 4px;
            }

            .country-code {
                font-size: 16px;
                line-height: 150%;
                color: $dark-color-op1;
            }
        }

        .divider {
            width: 0px;
            height: 26px;

            border: 1px solid $dark-color-three;
        }

        .input-wrapper {
            width: 70%;
            display: flex;
            justify-content: flex-end;

            input::placeholder {
                color: $dark-color-op2;
                font-size: 14px;
                letter-spacing: 0.015em;
            }

            .mobile-number-input {
                background: initial;

                border: none;

                &:focus {
                    outline: none;
                }
            }
        }

    }
}

.create-account {
    .upload-photo-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 28px;
        align-items: center;
        flex-direction: column;

        .upload-photo {
            border: 2px dashed #B8B8B8;
            height: 89px;
            width: 89px;
            border-radius: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            .upload-image {
                width: 54px;
                height: 53px;
            }
        }

        .upload-text {
            font-size: 16px;
            color: #3D3D3D;
            letter-spacing: 0.01em;
            margin-top: 8px;
            cursor: pointer;
        }
    }

    .gender-selection {
        margin-top: 24px;

        .label {
            margin-bottom: 12px;
        }

        .wrapper {
            display: flex;
            justify-content: space-evenly;
        }

        .selection-wrapper {
            width: 109px;
            height: 122px;
            background: $neutral-four;
            border-radius: 8px;
            text-align: -webkit-center;
            cursor: pointer;

            &.selected {
                outline: 2px solid #3D3D3D;
            }

            .male-icon {
                background-image: url(../../assets/images/register-page/male.png);
                height: 54px;
                width: 54px;
                background-size: cover;
                position: relative;
                top: 26px;
            }

            .female-icon {
                @extend .male-icon;
                background-image: url(../../assets/images/register-page/female.png);
            }

            .other-icon {
                @extend .male-icon;
                background-image: url(../../assets/images/register-page/other-gender.png);
            }

            .text {
                position: relative;
                top: 44px;
                font-size: 14px;
                color: #3D3D3D;
            }
        }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        display: block;
        background: url(../../assets/images/icons/calendar.svg) no-repeat;
        width: 20px;
        height: 20px;
    }

    .submit {
        display: flex;
        justify-content: center;
    }
}

.invite-friends {
    .image-wrapper {
        display: flex;
        justify-content: center;

        .friend-image {
            width: 375px;
            height: 376px;
            background-image: url(../../assets/images/register-page/invite-friends.png);
            background-size: cover;

            .invite-text-wrapper {
                position: relative;
                top: 222px;

                .share-text {
                    font-size: 16px;
                    color: #3D3D3D;
                    font-weight: 700;
                    text-align: center;
                }

                .share-subtext {
                    color: #B8B8B8;
                    font-size: 14px;
                    letter-spacing: 0.015em;
                    text-align: center;
                    margin-top: 8px;
                    line-height: 160%;
                }
            }

            .invite-code-wrapper {
                height: 48px;
                background: $dark-color-op3;
                border: 1px dashed $neutral-fifteen;
                position: relative;
                top: 222px;
                border-radius: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                .copy-text {
                    margin-left: 16px;
                    color: #565656;
                }

                .copy-icon {
                    background-image: url(../../assets/images/icons/copy.svg);
                    width: 28px;
                    height: 28px;
                    margin-right: 16px;
                }
            }
        }
    }

    .tap-code {
        color: #B8B8B8;
        font-size: 12px;
        text-align: center;
    }

    .share-with-wrapper {
        margin-top: 16px;

        .text {
            font-size: 12px;
            color: #292929;
            text-align: center;
        }
    }

    .social-wrapper {
        display: flex;
        justify-content: space-evenly;
        margin-top: 12px;

        .icon-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: fit-content;
            cursor: pointer;

            .icon-img {
                height: 44px;
                width: 44px;
                background-size: cover;
                background-repeat: no-repeat;

                &.whatsapp {
                    background-image: url(../../assets/images/register-page/WhatsApp.png);
                }

                &.instagram {
                    background-image: url(../../assets/images/register-page/Instagram.png);
                }

                &.twitter {
                    background-image: url(../../assets/images/register-page/Twitter.png);
                }

                &.other {
                    background-image: url(../../assets/images/register-page/Other.png);
                }
            }

            .icon-text {
                margin-top: 16px;
                color: #B8B8B8;
                font-size: 12px;
            }
        }
    }

    .submit {
        display: flex;
        justify-content: center;
    }
}

.get-started-scene {
    .get-started-one {
        background-image: url(../../assets/images/register-page/get-started-1.png);
        background-size: cover;
        background-repeat: no-repeat;
        height: 247px;
        display: flex;
        justify-content: center;

        .explore {
            width: 132px;
            height: 38px;
            background: $white-color;
            box-shadow: 0px 2px 12px rgba(35, 116, 236, 0.5);
            border-radius: 8px;
            padding: 8px 30px;
            cursor: pointer;
            position: relative;
            top: 80px;
            font-size: 16px;
            color: #3E6BD8;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .get-started-two {
        margin-top: 16px;
        background-image: url(../../assets/images/register-page/get-started-2.png);
        background-size: cover;
        background-repeat: no-repeat;
        height: 247px;
        display: flex;
        justify-content: center;

        .create {
            width: 132px;
            height: 38px;
            background: $white-color;
            box-shadow: 0px 2px 12px rgba(243, 66, 93, 0.6);
            border-radius: 8px;
            padding: 8px 30px;
            cursor: pointer;
            position: relative;
            top: 80px;
            font-size: 16px;
            color: #FF3C5F;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}