// Styles must use direct files imports
// @import 'swiper/swiper.scss'; // core Swiper
// @import 'swiper/modules/navigation/navigation.scss'; // Navigation module
// @import 'swiper/modules/pagination/pagination.scss'; // Pagination module

.upcoming-event-swiper {
  .swiper-button-next::before {
    background: transparent !important;
    display: none;
  }
  .swiper-button-next::after {
    background: transparent !important;
    padding: 10px;
    cursor: pointer;
  }
  .swiper-button-next {
    content: none;
    background-color: #fff !important;
    color: #fff;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 90%;
    border: none !important;
    box-shadow: none !important;
    height: 0;
    width: 0;
  }
  .swiper-button-prev::before {
    background: transparent !important;
    display: none;
  }
  .swiper-button-prev::after {
    background: transparent !important;
    padding: 10px;
    cursor: pointer;
  }
  .swiper-button-prev {
    content: none;
    background-color: #fff !important;
    color: #fff;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 0%;
    border: none !important;
    box-shadow: none !important;
    height: 0;
    width: 0;  
  }
}

.onboarding-swiper {
  .swiper-button-next::before {
    background: transparent !important;
    display: none;
  }
  .swiper-button-next::after {
    background: transparent !important;
    padding: 10px;
    cursor: pointer;
  }
  .swiper-button-next {
    content: none;
    background-color: #fff !important;
    color: #fff;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 90%;
    border: none !important;
    box-shadow: none !important;
    height: 0;
    width: 0;
  }
  .swiper-button-prev::before {
    background: transparent !important;
    display: none;
  }
  .swiper-button-prev::after {
    background: transparent !important;
    padding: 10px;
    cursor: pointer;
  }
  .swiper-button-prev {
    content: none;
    background-color: #fff !important;
    color: #fff;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 5%;
    border: none !important;
    box-shadow: none !important;
    height: 0;
    width: 0;  
  }
}


.swiper-slide {
  visibility: hidden;
}

.swiper-slide.swiper-slide-active {
  visibility: visible;
}

.create-community {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(182, 180, 180, 0.6);
  backdrop-filter: blur(25px);

  .base-card {
    width: 694px;
    height: auto;
    background-color: #ffffff;
    z-index: 1000;
    border-radius: 8px;
    overflow: hidden;

    .base-card-header {
      display: flex;
      padding: 32px 35px;

      .base-header-container {
        width: 80%;
        display: flex;
        flex-direction: column;

        .base-header-title {
          font-size: 28px;
          line-height: 28px;
          color: $dark-color-two;
          font-weight: 600;
          margin-left: 20px;
        }
      }

      .base-back-navigation {
        cursor: pointer;
      }

      .base-card-close-container {
        background-color: $neutral-six;
        height: 28px;
        width: 28px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        cursor: pointer;

        .base-card-close-icon {
          &::after {
            display: inline-block;
            content: '\00d7';
            font-size: 22px;
          }
        }
      }
    }

    .base-card-body {
      padding: 0 35px 32px 35px;
      height: 70vh;
      overflow: auto;

      .float-right {
        float: right;
      }
    }

    .cover-image-wrapper {
      height: 264px;
      background-image: url(../../assets/images/community/image-picker-background.png);
      background-size: 100% 100%;
      border-radius: 16px;
      display: flex;

      .cover-image {
        height: 100%;
        width: 100%;
      }

      .change-cover-image-wrapper {
        display: flex;
        justify-content: flex-end;
        height: fit-content;
        width: 100%;

        .change-cover-image {
          width: 227px;
          height: 37px;
          position: relative;
          top: 216px;
          background: rgba(5, 5, 5, 0.65);
          border-radius: 8px;
          bottom: 0;
          right: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .change-cover-icon {
            background-image: url(../../assets/images/icons/image.svg);
            background-size: cover;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }

          .change-cover-text {
            font-size: 16px;
            color: $white-color;
            font-family: Geomanist Book;
            margin-top: 4px;
          }
        }
      }

      .community-icon-wrapper {
        background: #494551;
        border: 4px solid #fafafa;
        border-radius: 8px;
        width: 70px;
        height: 64px;
        position: relative;
        top: 225px;
        left: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;

        .community-icon {
          background-image: url(../../assets/images/icons/image.svg);
          background-size: cover;
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
        }
      }
    }

    .community-icon-text {
      margin-top: 40px;
      margin-left: 16px;
      font-size: 16px;
      color: $dark-color-one;
      font-family: Geomanist Book;
    }

    .community-category-list-wrapper {
      display: flex;
      justify-content: flex-start;
      margin-top: 12px;
      width: 100%;
      flex-wrap: wrap;

      .category {
        width: 87px;
        height: 30px;
        border: 1px solid rgba(8, 8, 8, 0.25);
        border-radius: 21px;
        font-size: 16px;
        color: rgba(8, 8, 8, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;

        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        margin: 5px;

        &.selected {
          background-color: #3d3d3d;
          color: $white-color;
        }
      }
    }

    .community-accent-wrapper {
      display: flex;
      justify-content: flex-start;
      margin-top: 12px;
      width: 100%;
      flex-wrap: wrap;

      .community-accent {
        width: 107px;
        height: 42px;
        border-radius: 8px;

        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .selected {
          height: 20px;
          width: 20px;
          background-image: url(../../assets/images/icons/checkmark.svg);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    .community-input-wrapper {
      margin-top: 16px;
      display: flex;
      cursor: pointer;

      &.mt-24 {
        margin-top: 24px;
      }

      .side-icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 60px;

        &.green {
          background: rgba(91, 195, 136, 0.2);
        }

        &.pink {
          background: rgba(255, 60, 95, 0.2);
        }

        .icon {
          width: 24px;
          height: 24px;
          background-size: cover;
          background-repeat: no-repeat;
        }

        .globe {
          background-image: url(../../assets/images/icons/globe.svg);
        }

        .star {
          background-image: url(../../assets/images/icons/star-circle.png);
        }
      }

      .text-wrapper {
        width: 70%;
        padding-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .title {
          font-size: 16px;
          color: $dark-color-one;
          font-family: Geomanist Book;
        }

        .subtitle {
          margin-top: 4px;
          font-size: 14px;
          color: $dark-color-five;

          &.selected {
            color: $dark-color-four;
          }
        }
      }

      .selection-icon-wrapper {
        display: flex;
        align-items: center;
        width: 20%;
        justify-content: center;

        .selection-icon {
          width: 24px;
          height: 24px;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url(../../assets/images/icons/check_circle.svg);
        }

        .selected {
          background-image: url(../../assets/images/icons/check_circle_selected.svg);
        }
      }
    }

    .submit-wrapper {
      text-align: center;
    }
  }

  &.display-none {
    display: none;
  }
}

.community-overview {
  padding: 12px;
}

.grid-rows-modal {
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.row-span-content {
  grid-row: 2 / -2;
}

.row-span-footer {
  grid-row: -1;
}

.tox-notifications-container{
  display: none;
}

.tox .tox-toolbar--scrolling {
  flex-wrap: wrap !important;
  overflow-x: hidden !important;
  width: 100%;
}

.tox .tox-toolbar__group {
  display: flex;
  flex-wrap: wrap !important;
  flex-shrink: unset !important;
}
