.auth-base-container {
    width: 100%;
    height: 100vh;
    background-color: #E5E5E5;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .height-auto {
        height: auto;
    }
}

.auth-base-card {
    width: 462px;
    height: 586px;
    background-color: #FFFFFF;
    z-index: 1;
    border-radius: 8px;
    overflow: hidden;
    padding: 32px 35px;
}

.auth-base-back-navigation {
    width: 10%;
    cursor: pointer;
}

.align-center {
    align-items: center;
}

.auth-base-card-image-wrapper {
    height: 100%;
    margin: -32px -35px;
    display: flex;
    align-items: flex-end;

    .auth-base-card-image {
        background-image: url('../../assets/images/login-page/card-bottom.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: relative;
        bottom: 26px;
        z-index: -1;
    }
}

.auth-base-front-image {
    background-image: url(../../assets/images/login-page/main-front.png);
    position: absolute;
    bottom: 0;
    height: 42vw;
    max-height: calc(100vh * 0.60);
    width: 100%;
    background-size: 100% 100%;

    filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
}

.auth-base-card-header {
    display: flex;

    .auth-base-header-container {
        width: 80%;
        display: flex;
        flex-direction: column;

        .auth-base-header-title {
            font-size: 28px;
            line-height: 28px;
            color: $dark-color-two;
            font-weight: 600;
        }

        .auth-base-header-subtitle {
            margin-top: 11px;
            font-size: 12px;
            color: $dark-color-four;
        }
    }

    .auth-base-card-close-container {
        background-color: $neutral-six;
        height: 28px;
        width: 28px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

        .auth-base-card-close-icon {
            &::after {
                display: inline-block;
                content: "\00d7";
                font-size: 22px;
            }
        }
    }
}