.overview_card_wrapper{
  display: flex;
  padding: 3rem 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;

  border-radius: 0.5rem;
  border: 0.795px solid var(--default-gray-200, #E5E7EB);
  background: var(--default-white, #FFF);
  margin-bottom: 1.5rem;

  .overview_card_w__clock{
    display: flex;
    height: 6rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    .overview_card_w__clock__clock_timer{
      width: 6rem;
      height: 6rem;
      background: url('/assets/images/reminder-component/notify-admin-expired-icon.svg'), no-repeat;
      mix-blend-mode: multiply;
    }
    .admin_icon{
      width: 6rem;
      height: 6rem;
      background: url('/assets/images/reminder-component/trial-ended.svg'), no-repeat;
      mix-blend-mode: multiply;      
    }
  }

  .overview_card__content_w{
    display: flex;
    padding: 0rem 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;    

    border-radius: 0.5rem;
    // border: 0.795px solid var(--default-gray-200, #E5E7EB);
    background: var(--default-white, #FFF);

    .overview_card__content_w__content{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;
      align-self: stretch;

      .overview_card__content_w__content_heading_2{
        color: var(--gray-900, #111827);
        text-align: center;
        font-family: Geomanist;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 1.5rem */
      }
      .overview_card__content_w__content_para{
        color: var(--default-gray-700, #374151);
        text-align: center;
        font-family: Geomanist;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 1.3rem */
      }
    }
    .overview_card__content_w__action{
      display: flex;
      width: 31.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;

      .overview_card__content_w__action_button{
        display: flex;
        height: 2.25rem;
        padding: 0.5rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;     
        border-radius: 0.25rem;
        background: var(--default-indigo-600, #4F46E5);
        color: var(--white, #FFF);
        text-align: center;
        font-family: Geomanist;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 0.875rem */
      }

      .overview_card__content_w__action_button-2 {
        background: var(--default-gray-800, #1F2937);
        color: var(--default-white, #FFF);
      }

    }
  }
}

@media (max-width:640px) {
  .overview_card_wrapper .overview_card__content_w .overview_card__content_w__content .overview_card__content_w__content_para {
    color: var(--default-gray-700, #374151);
    text-align: center;
  
    /* text-sm/leading-5/font-normal */
    font-family: Geomanist;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
  }
  .overview_card_wrapper .overview_card__content_w .overview_card__content_w__content .overview_card__content_w__content_heading_2{
    color: var(--gray-900, #111827);
    text-align: center;

    /* text-xl/leading-7/font-medium */
    font-family: Geomanist;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 140% */
  }
}