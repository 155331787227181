.main_w{
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  gap: 1rem;
  border: 1px solid var(--default-indigo-400, #818CF8);
  background: var(--default-indigo-500, #6366F1);
  margin-bottom: 1.5rem;
  position: relative;

  .timer_img{
    background: url('/assets/images/reminder-component/trial-left-icon.svg');

    background-repeat: no-repeat;
    width: 8.625rem;
    height: 6.5rem;
    z-index: 2;
    background-position-y: 40%;
  }

  .bg_corner{
    background: url("/assets/images/reminder-component/bg-grid.svg");

    width: 15.49144rem;
    height: 10.6795rem;
    /* transform: rotate(134.078deg); */
    bottom: 0;
    position: absolute;
    right: 0;
    mix-blend-mode: luminosity;
    top: 0;
    background-repeat: no-repeat;
    background-position-x: 100%;
    z-index: 1;
  }

  .content_w{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    .content_ww{
      display: flex;
      align-items: center;
      gap: 16px;

      .remianing_days{
        width: 2.5rem;
        height: 2.5rem;
        position: relative;
        .ndays{
          color: var(--default-white, #FFF);
          text-align: center;
          font-family: Geomanist;
          font-size: 1rem;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 1rem */
          letter-spacing: -0.03rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

        }
        .loader_reminder{
          width: 2.5rem;
          height: 2.5rem;
          position: absolute;
        }
      }
      .text_w{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.25rem;
        .text_w_para{
          color: var(--default-indigo-200, #C7D2FE);
          font-family: Geomanist;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 1.5rem */
          z-index: 4;
        }
        .text_top_row{
          color: var(--default-gray-50, #F9FAFB);
          text-align: center;
          font-family: Geomanist;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 1.25rem */
        }
        .text_bottom_row{
          display: flex;
          align-items: center;
          gap: 0.375rem;
          border-radius: 2.5rem;
          .text_bottom_row_left{
            --img_width: 1rem;
            display: flex;
            position: relative;
            height: 1rem;
            .circular_img_1{
              width: var(--img_width);
              height: 1rem;
              flex-shrink: 0;
              border-radius: 0.92306rem;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              border: 1px solid var(--default-indigo-500, #6366F1);
              background: url("/assets/images/reminder-component/avatar1.png"), lightgray -3.908px -0.085px / 148.846% 148.846% no-repeat;
            }
            .circular_img_2{
              width: var(--img_width);
              height: 1rem;
              flex-shrink: 0;
              border-radius: 0.92306rem;
              position: absolute;
              top: 0; 
              left: calc(var(--img_width) * .8);
              bottom: 0;
              border: 1px solid var(--default-indigo-500, #6366F1);
              background: url("/assets/images/reminder-component/avatar2.png"), lightgray -5.167px -0.334px / 164.584% 164.584% no-repeat;
            }
            .circular_img_3{
              width: var(--img_width);
              height: 1rem;
              flex-shrink: 0;
              border-radius: 0.92306rem;
              position: absolute;
              top: 0;
              left: calc(var(--img_width) * 2 * .8);
              bottom: 0;
              border: 1px solid var(--default-indigo-500, #6366F1);
              background: url("/assets/images/reminder-component/avatar3.png"), lightgray -2.38px -0.143px / 130.434% 130.434% no-repeat;
            }
          }
          .text_bottom_row_right{
            color: var(--default-indigo-100, #E0E7FF);
            font-family: Geomanist;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            margin-left: 3rem;
          }
        }
      }
    }

    .action_button{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
      .textbtn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--default-gray-800, #1F2937);
        text-align: center;
        font-family: Geomanist;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        padding: 0.5rem 1rem;
        background: var(--default-white, #FFF);
        border-radius: 0.25rem;
        height: 2.25rem;
      }
      .textbtn-2 {
        background: var(--default-gray-800, #1F2937);
        color: var(--default-white, #FFF);
      }
    }
  }
  .cross_w{
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
    z-index: 5;
    transition: all 0.3s ease;
    &:hover {
      background: #4F46E5;
    }
  }
}

.cross_w_2{
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 5;
}

.popup_main_w{
  height: 100vh;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .close_modal{
    position: absolute;
    top: 1.3rem;
    right: 1rem;
    z-index: 40;
    padding: 10px;
    cursor: pointer;
    background: white;
    border-radius: 50%;
  }
}

.pop_up_iframe{
  width: 100%;
  height: 100%;
}

#svg circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
  stroke: #666;
  stroke-width: 1em;
}
#svg #bar {
  stroke: #FF9F1E;
}
#cont {
  display: block;
  height: 200px;
  width: 200px;
  margin: 2em auto;
  box-shadow: 0 0 1em black;
  border-radius: 100%;
  position: relative;
}
#cont:after {
  position: absolute;
  display: block;
  height: 160px;
  width: 160px;
  left: 50%;
  top: 50%;
  box-shadow: inset 0 0 1em black;
  content: attr(data-pct)"%";
  margin-top: -80px;
  margin-left: -80px;
  border-radius: 100%;
  line-height: 160px;
  font-size: 2em;
  text-shadow: 0 0 0.5em black;
}

.CircularProgressbar .CircularProgressbar-path, .CircularProgressbar-trail{
  stroke-width: 10;
}

.row_2{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}
.text_bottom_row{
  display: flex;
  align-items: center;
  border-radius: 2.5rem;
  gap: 1rem;
  .text_bottom_row_left{
    --img_width: 1rem;
    position: relative;
    display: flex;
    .circular_img_1{
      width: var(--img_width);
      height: 1rem;
      flex-shrink: 0;
      border-radius: 0.92306rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border: 1px solid var(--default-indigo-500, #6366F1);
      background: url("/assets/images/reminder-component/avatar1.png"), lightgray -3.908px -0.085px / 148.846% 148.846% no-repeat;
    }
    .circular_img_2{
      width: var(--img_width);
      height: 1rem;
      flex-shrink: 0;
      border-radius: 0.92306rem;
      position: absolute;
      top: 0;
      left: calc(var(--img_width) * .8);
      bottom: 0;
      border: 1px solid var(--default-indigo-500, #6366F1);
      background: url("/assets/images/reminder-component/avatar2.png"), lightgray -5.167px -0.334px / 164.584% 164.584% no-repeat;
    }
    .circular_img_3{
      width: var(--img_width);
      height: 1rem;
      flex-shrink: 0;
      border-radius: 0.92306rem;
      position: absolute;
      top: 0;
      left: calc(var(--img_width) * 2 * .8);
      bottom: 0;
      border: 1px solid var(--default-indigo-500, #6366F1);
      background: url("/assets/images/reminder-component/avatar3.png"), lightgray -2.38px -0.143px / 130.434% 130.434% no-repeat;
    }
  }
  .text_bottom_row_right{
    color: var(--default-indigo-100, #E0E7FF);
    font-family: Geomanist;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.text_bottom_row_type_2{
  margin-top: 1rem;
}

.remaining_days{
  width: 3.25rem;
  height: 3.25rem;
}

.type_2_text{
  margin-left: 3rem;
}

.bottom_row_w{
  display: flex;
  align-items: center;

  .text_bottom_row_left{
    --img_width: 1rem;
    display: flex;
    position: relative;
    height: 1rem;
    .circular_img_1{
      width: var(--img_width);
      height: 1rem;
      flex-shrink: 0;
      border-radius: 0.92306rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border: 1px solid var(--default-indigo-500, #6366F1);
      background: url("/assets/images/reminder-component/avatar1.png"), lightgray -3.908px -0.085px / 148.846% 148.846% no-repeat;
    }
    .circular_img_2{
      width: var(--img_width);
      height: 1rem;
      flex-shrink: 0;
      border-radius: 0.92306rem;
      position: absolute;
      top: 0; 
      left: calc(var(--img_width) * .8);
      bottom: 0;
      border: 1px solid var(--default-indigo-500, #6366F1);
      background: url("/assets/images/reminder-component/avatar2.png"), lightgray -5.167px -0.334px / 164.584% 164.584% no-repeat;
    }
    .circular_img_3{
      width: var(--img_width);
      height: 1rem;
      flex-shrink: 0;
      border-radius: 0.92306rem;
      position: absolute;
      top: 0;
      left: calc(var(--img_width) * 2 * .8);
      bottom: 0;
      border: 1px solid var(--default-indigo-500, #6366F1);
      background: url("/assets/images/reminder-component/avatar3.png"), lightgray -2.38px -0.143px / 130.434% 130.434% no-repeat;
    }
  }
}

.upgrade_to_unlock_wrapper{
  display: flex;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  border-radius: 2.0625rem;
  border: 0.5px solid #4F46E5;
  background: #F4F3FF;
  .upgrade_to_unlock_icon{
    width: 0.75rem;
    height: 0.75rem;
  }
  .upgrade_to_unlock{
    color: #4F46E5;
    font-family: Geomanist;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.015rem;
  }
}
.mobile_bg_image{
  display: none;
}

.cross_w_mobile{
  display: none;
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 4px;
  &:hover {
    background: #4F46E5;
  } 
}
.cross_w_desktop{
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 4px;
  &:hover {
    background: #4F46E5;
  } 
}


@media (max-width: 767px){
  .text_top_row{
    font-size: 1rem !important;
  }

  .cross_w_mobile{
    display: flex;
    position: absolute;
    right: 0;
  }
  .cross_w_desktop{
    display: none;
  }

  .main_w .content_w .action_button .textbtn{
    padding: 0.5rem 0.5rem;
    width: 100%;
  }

  .circle-loader{
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .text_bottom_row_right{
    z-index: 4;
  }
  .main_w .timer_img{
    width: 5rem;
    background-position-x: 50%;
    display: none;
  }
  .text_bottom_row_type_2{
    flex-direction: column;
  }
  .main_w .content_w .content_ww{
    align-items: flex-start;
    flex-direction: column;

  }

  .main_w .bg_corner{
    background-position-y: 50%;
    display: none;
  }

  .main_w .content_w .action_button{
    width: 100%;
    flex-direction: column;
  }
  .main_w .content_w .content_ww .text_w .text_top_row{
    color: var(--default-gray-50, #F9FAFB);

    /* text-xl/leading-7/font-medium */
    font-family: Geomanist;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 140% */
  }
  .main_w .content_w .content_ww .text_w .text_w_para{
    color: var(--default-indigo-200, #C7D2FE);
    /* text-xs/leading-4/font-normal */
    font-family: Geomanist;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
  }
  .main_w .content_w{
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
  .mobile_bg_image{
    display: block;
    width: 7.0625rem;
    height: 3.625rem;
    position: absolute;
    right: 0;
  }
  .main_w .content_w .content_ww{
    flex-direction: row;
    align-items: center;
  }
  .main_w .content_w .content_ww.type_two_mobile{
    flex-direction: column;
    align-items: flex-start;
  }
}

.mobile_bg_image{
  background: url('/assets/images/reminder-component/trial-left-icon.svg');
  background-repeat: no-repeat;
}