.common-button-base {
  height: 44px;
  padding: 10px 10px 8px;
  border: 1px solid $dark-color-two;
  box-sizing: border-box;
  border-radius: 8px;
  background: $white-color;
  width: auto;
  font-family: Geomanist Book;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  color: $dark-color-two;
  cursor: pointer;

  &.button-black {
    background: #292929;
    color: #FFFFFF;
  }
}

.base-input {
  display: flex;
  flex-direction: column;

  input::placeholder {
    color: $dark-color-op2;
    font-size: 14px;
    letter-spacing: 0.015em;
  }

  .label {
    color: $dark-color-one;
    font-size: 16px;
    line-height: 16px;
    font-family: Geomanist Book;
  }

  .input-field {
    border-radius: 8px;
    background: $dark-color-three;
    padding: 13px 16px;
    margin-top: 12px;
    font-family: Geomanist;
    border: none;

    &:focus {
      outline: none;
    }
  }
}

.error-text-container {
  .error-text {
    color: $red-one;
    font-size: 12px;
  }
}

.base-card-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(182, 180, 180, 0.6);
  backdrop-filter: blur(25px);
  z-index: 999;

  .base-card {
    width: 694px;
    height: auto;
    background-color: #FFFFFF;
    z-index: 1000;
    border-radius: 8px;
    overflow: hidden;

    .base-card-header {
      display: flex;
      padding: 32px 35px;

      .base-header-container {
        width: 80%;
        display: flex;
        flex-direction: column;

        .base-header-title {
          font-size: 28px;
          line-height: 28px;
          color: $dark-color-two;
          font-weight: 600;

          &.ml-20 {
            margin-left: 20px;
          }
        }
      }

      .base-back-navigation {
        cursor: pointer;
      }

      .base-card-close-container {
        background-color: $neutral-six;
        height: 28px;
        width: 28px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        cursor: pointer;

        .base-card-close-icon {
          &::after {
            display: inline-block;
            content: "\00d7";
            font-size: 22px;
          }
        }
      }
    }

    .base-card-body {
      padding: 0 35px 32px 35px;
      height: 70vh;
      overflow: auto;

      &.is-auto-height {
        height: auto;
      }
    }
  }
}