.scrollbar {
  height: 250px;
  overflow: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 4px !important;
  height: 80px !important;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #D1D5DB; ;
}

