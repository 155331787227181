$white-color: #FFFFFF;

$dark-color-one: #080808;
$dark-color-two: #292929;
$dark-color-three: rgba(8, 8, 8, 0.05);
$dark-color-four: #565656;
$dark-color-five: #B8B8B8;
$dark-color-op2: rgba(8, 8, 8, 0.25);
$dark-color-op1: rgba(8, 8, 8, 0.75);
$dark-color-op3: rgba(8, 8, 8, 0.05);

$neutral-six: rgba(5, 5, 5, 0.06);
$neutral-four: rgba(0, 0, 0, 0.04);
$neutral-fifteen: rgba(5, 5, 5, 0.15);

$green-one: #74c994;
$green-primary: #5BC388;

$red-one: #db4344;